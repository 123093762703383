
import { debounceProcess } from "@/helpers/debounce";
import { useCurrency } from "@/hooks";
import { Option } from "@/models/class/option.class";
import { RequestQueryParams } from "@/models/class/request-query-params.class";
import { Vue, Component } from "vue-property-decorator";

/**
 * @deprecated
 */
@Component
export default class MCurrency extends Vue {
  currencyOptions: Option[] = [];
  loadingCurrency = false;

  created(): void {
    this.searchCurrency = debounceProcess(this.searchCurrency);
  }

  fetchCurrency(params: RequestQueryParams): void {
    const { findAll } = useCurrency();
    this.loadingCurrency = true;
    findAll(params)
      .then(res => {
        this.currencyOptions = res.data.map(item => ({
          label: item.currencyCode || "",
          value: item.currencyCode || "",
          key: item.id || "",
        }));
      })
      .finally(() => {
        this.loadingCurrency = false;
      });
  }

  searchCurrency(value: string): void {
    const { searchBy } = useCurrency();
    const params = new RequestQueryParams();
    params.search = searchBy({
      code: value,
    });

    this.fetchCurrency(params);
  }
}
