
import { debounceProcess } from "@/helpers/debounce";
import { useContactData, useMapContactToOption } from "@/hooks";
import { Option } from "@/models/class/option.class";
import { RequestQueryParams } from "@/models/class/request-query-params.class";
import { ContactData } from "@/models/interface/contact.interface";
import { Component, Mixins } from "vue-property-decorator";
import MNotificationVue from "./MNotification.vue";

/**
 * @deprecated
 */
@Component
export default class MSupplier extends Mixins(MNotificationVue) {
  supplierOptions: Option<ContactData>[] = [];
  loadingSUpplier = false;

  created(): void {
    this.onSearchSupplier = debounceProcess(this.onSearchSupplier);
  }

  fetchSupplier(params: RequestQueryParams): void {
    const { findSuppliers } = useContactData();
    this.loadingSUpplier = true;
    findSuppliers(params)
      .then(res => {
        this.supplierOptions = useMapContactToOption(res);
      })
      .finally(() => {
        this.loadingSUpplier = false;
      });
  }

  onSearchSupplier(search = ""): void {
    const { filterBy } = useContactData();
    const params = new RequestQueryParams();
    params.search = filterBy({ firstName: search, lastName: search });

    this.fetchSupplier(params);
  }
}
