








































































































































































import { Row, useAgingApReport, useBlob, useDate } from "@/hooks";
import MCurrency from "@/mixins/MCurrency.vue";
import MMasterBranch from "@/mixins/MMasterBranch.vue";
import MNotificationVue from "@/mixins/MNotification.vue";
import MSupplier from "@/mixins/MSupplier.vue";
import { RequestQueryParams } from "@/models/class/request-query-params.class";
import { PAGE_SIZE_OPTIONS } from "@/models/constant/global.constant";
import { Nullable } from "@/models/constant/interface/common.interface";
import {
  DATE_FORMAT_YYYY_MM_DD,
  DEFAULT_DATE_FORMAT,
} from "@/models/constants/date.constant";
import {
  ReportAgingApListDto,
  ReportAgingApResponseDto,
} from "@/models/interface/AgingApReport.interface";
import { FormModel } from "ant-design-vue";
import moment, { Moment } from "moment";
import { Component, Mixins, Ref } from "vue-property-decorator";

type FormValue = Nullable<{
  currencyCode: string;
  branchId: string;
  branchName: string;
  supplierId: string;
  supplierName: string;
  supplierType: string;
  asOfDate: Moment;
}>;

const FIRST_PAGE = 1;
const DEFAULT_LIMIT = 10;

@Component
export default class AgingApReport extends Mixins(
  MNotificationVue,
  MMasterBranch,
  MSupplier,
  MCurrency
) {
  @Ref("form") form!: FormModel;
  DEFAULT_DATE_FORMAT = DEFAULT_DATE_FORMAT;
  PAGE_SIZE_OPTIONS = PAGE_SIZE_OPTIONS;

  formModel: FormValue = {
    branchId: "",
    branchName: "",
    supplierId: "",
    supplierName: "",
    supplierType: "",
    currencyCode: "",
    asOfDate: moment(),
  };

  formRules = {
    branchId: [],
    branchName: [],
    supplierId: [],
    supplierName: [],
    supplierType: [],
    currencyCode: [],
    asOfDate: [
      { required: true, message: this.$t("lbl_validation_required_error") },
    ],
  };

  dataReport: ReportAgingApResponseDto = useAgingApReport().initReport();
  dataSource: Row<ReportAgingApListDto, number>[] = [];
  columns = [
    {
      title: this.$t("lbl_supplier_number"),
      dataIndex: "supplierCode",
      scopedSlots: { customRender: "nullable" },
    },
    {
      title: this.$t("lbl_supplier_name"),
      dataIndex: "supplierName",
      width: "300px",
      scopedSlots: { customRender: "nullable" },
    },
    {
      title: this.$t("lbl_supplier_type"),
      dataIndex: "supplierType",
      scopedSlots: { customRender: "nullable" },
    },
    {
      title: this.$t("lbl_current"),
      dataIndex: "current",
      scopedSlots: { customRender: "currency" },
    },
    {
      title: this.$t("lbl_1_to_30_days"),
      dataIndex: "outstanding1",
      scopedSlots: { customRender: "currency" },
    },
    {
      title: this.$t("lbl_31_to_60_days"),
      dataIndex: "outstanding2",
      scopedSlots: { customRender: "currency" },
    },
    {
      title: this.$t("lbl_61_to_90_days"),
      dataIndex: "outstanding3",
      scopedSlots: { customRender: "currency" },
    },
    {
      title: this.$t("lbl_more_90_days"),
      dataIndex: "outstanding4",
      scopedSlots: { customRender: "currency" },
    },
    {
      title: this.$t("lbl_total"),
      dataIndex: "total",
      scopedSlots: { customRender: "currency" },
    },
  ];

  loading = {
    find: false,
    download: false,
  };

  pagination = {
    page: FIRST_PAGE,
    limit: DEFAULT_LIMIT,
    reset(): void {
      this.page = FIRST_PAGE;
      this.limit = DEFAULT_LIMIT;
    },
  };

  created(): void {
    this.fetchBranch(new RequestQueryParams());
    this.fetchSupplier(new RequestQueryParams());
    this.fetchCurrency(new RequestQueryParams());
  }

  buildSearch(): string {
    const { filterBy } = useAgingApReport();
    return filterBy({
      branchId: this.formModel.branchId || "",
      supplierId: this.formModel.supplierId || "",
      supplierType: this.formModel.supplierType || "",
      currencyCode: this.formModel.currencyCode || "",
    });
  }

  handleSubmit(): void {
    const { findAll } = useAgingApReport();
    const { toAsOfDate } = useDate();

    const params = new RequestQueryParams();
    params.search = this.buildSearch();

    if (this.formModel.asOfDate) {
      params.asOfDate = toAsOfDate(this.formModel.asOfDate);
    }

    this.loading.find = true;
    findAll(params)
      .then(res => {
        this.dataReport = res;
        this.dataSource = res.reportAgingListDTOApList.map((item, i) => ({
          key: i,
          ...item,
        }));
      })
      .finally(() => {
        this.loading.find = false;
      });
  }

  onChangeTable(pagination): void {
    const { current, pageSize } = pagination;
    this.pagination.page = current;

    if (pageSize !== this.pagination.limit) {
      this.pagination.page = FIRST_PAGE;
    }

    this.pagination.limit = pageSize;
  }

  download(): void {
    const { toAsOfDate } = useDate();
    const { toDownload } = useBlob();
    const { download, buildParamsDownload } = useAgingApReport();

    const params = new RequestQueryParams();
    params.search = this.buildSearch();
    params.params = buildParamsDownload({
      branchName: this.formModel.branchName || "",
      supplierName: this.formModel.supplierName || "",
      supplierType: this.formModel.supplierType || "",
      currencyCode: this.formModel.currencyCode || "",
    });

    if (this.formModel.asOfDate) {
      params.asOfDate = toAsOfDate(this.formModel.asOfDate);
    }

    this.loading.download = true;
    download(params)
      .then(res => {
        toDownload(res, "aging_ap_report.xlsx");
      })
      .finally(() => {
        this.loading.download = false;
      });
  }

  onChangeBranch(value: string | null): void {
    this.formModel.branchId = value || "";

    const option = this.branchOptions.find(item => item.value === value);
    if (!option) return;
    this.formModel.branchName = option.meta?.name || "";
  }

  onChangeSupplier(value: string | null): void {
    this.formModel.supplierId = value || "";

    const option = this.supplierOptions.find(item => item.value === value);
    if (!option) return;
    this.formModel.supplierName = option.meta?.fullName || "";
  }

  resetForm(): void {
    this.form.resetFields();
    this.formModel.branchName = "";
    this.formModel.supplierName = "";
    this.formModel.asOfDate = null;
  }
}
