var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-card",
    { attrs: { title: _vm.$t("lbl_aging_ap_report") } },
    [
      _c(
        "a-form-model",
        {
          ref: "form",
          attrs: {
            model: _vm.formModel,
            rules: _vm.formRules,
            "wrapper-col": { span: 12 },
            "label-col": { span: 6 },
            "label-align": "left"
          },
          nativeOn: {
            submit: function($event) {
              $event.preventDefault()
              return _vm.handleSubmit.apply(null, arguments)
            }
          }
        },
        [
          _c(
            "a-row",
            { attrs: { gutter: [16, 16] } },
            [
              _c(
                "a-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "a-form-model-item",
                    {
                      attrs: { label: _vm.$t("lbl_branch"), prop: "branchId" }
                    },
                    [
                      _c(
                        "a-select",
                        {
                          attrs: {
                            "allow-clear": "",
                            loading: _vm.loadingBranch,
                            "filter-option": false,
                            "show-search": ""
                          },
                          on: {
                            search: _vm.onSearchBranch,
                            change: _vm.onChangeBranch
                          },
                          model: {
                            value: _vm.formModel.branchId,
                            callback: function($$v) {
                              _vm.$set(_vm.formModel, "branchId", $$v)
                            },
                            expression: "formModel.branchId"
                          }
                        },
                        _vm._l(_vm.branchOptions, function(item) {
                          return _c(
                            "a-select-option",
                            { key: item.key, attrs: { value: item.value } },
                            [
                              _c("a-tooltip", [
                                _c(
                                  "span",
                                  { attrs: { slot: "title" }, slot: "title" },
                                  [_vm._v(" " + _vm._s(item.label) + " ")]
                                ),
                                _vm._v(" " + _vm._s(item.label) + " ")
                              ])
                            ],
                            1
                          )
                        }),
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    {
                      attrs: {
                        label: _vm.$t("lbl_supplier"),
                        prop: "supplierId"
                      }
                    },
                    [
                      _c(
                        "a-select",
                        {
                          attrs: {
                            "allow-clear": "",
                            loading: _vm.loadingBranch,
                            "filter-option": false,
                            "show-search": "",
                            dropdownMatchSelectWidth: false
                          },
                          on: {
                            search: _vm.onSearchSupplier,
                            change: _vm.onChangeSupplier
                          },
                          model: {
                            value: _vm.formModel.supplierId,
                            callback: function($$v) {
                              _vm.$set(_vm.formModel, "supplierId", $$v)
                            },
                            expression: "formModel.supplierId"
                          }
                        },
                        _vm._l(_vm.supplierOptions, function(item) {
                          return _c(
                            "a-select-option",
                            { key: item.key, attrs: { value: item.value } },
                            [
                              _c("a-tooltip", [
                                _c(
                                  "span",
                                  { attrs: { slot: "title" }, slot: "title" },
                                  [_vm._v(" " + _vm._s(item.label) + " ")]
                                ),
                                _vm._v(" " + _vm._s(item.label) + " ")
                              ])
                            ],
                            1
                          )
                        }),
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    {
                      attrs: {
                        label: _vm.$t("lbl_currency"),
                        prop: "currencyCode"
                      }
                    },
                    [
                      _c(
                        "a-select",
                        {
                          attrs: {
                            "allow-clear": "",
                            "show-search": "",
                            "filter-option": false,
                            loading: _vm.loadingCurrency
                          },
                          on: { search: _vm.searchCurrency },
                          model: {
                            value: _vm.formModel.currencyCode,
                            callback: function($$v) {
                              _vm.$set(_vm.formModel, "currencyCode", $$v)
                            },
                            expression: "formModel.currencyCode"
                          }
                        },
                        _vm._l(_vm.currencyOptions, function(item) {
                          return _c(
                            "a-select-option",
                            { key: item.key, attrs: { value: item.value } },
                            [
                              _c("a-tooltip", [
                                _c(
                                  "span",
                                  { attrs: { slot: "title" }, slot: "title" },
                                  [_vm._v(" " + _vm._s(item.label) + " ")]
                                ),
                                _vm._v(" " + _vm._s(item.label) + " ")
                              ])
                            ],
                            1
                          )
                        }),
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "a-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "a-form-model-item",
                    {
                      attrs: {
                        label: _vm.$t("lbl_supplier_type"),
                        "label-align": "right",
                        prop: "supplierType"
                      }
                    },
                    [
                      _c("supplier-type-select", {
                        model: {
                          value: _vm.formModel.supplierType,
                          callback: function($$v) {
                            _vm.$set(_vm.formModel, "supplierType", $$v)
                          },
                          expression: "formModel.supplierType"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    {
                      attrs: {
                        label: _vm.$t("lbl_as_of_date"),
                        "label-align": "right",
                        prop: "asOfDate"
                      }
                    },
                    [
                      _c("a-date-picker", {
                        attrs: {
                          "allow-clear": "",
                          format: _vm.DEFAULT_DATE_FORMAT
                        },
                        model: {
                          value: _vm.formModel.asOfDate,
                          callback: function($$v) {
                            _vm.$set(_vm.formModel, "asOfDate", $$v)
                          },
                          expression: "formModel.asOfDate"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "a-row",
            { attrs: { gutter: [16, 16] } },
            [
              _c(
                "a-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "a-space",
                    [
                      _c("a-button", { on: { click: _vm.resetForm } }, [
                        _vm._v(" " + _vm._s(_vm.$t("lbl_reset")) + " ")
                      ]),
                      _c(
                        "a-button",
                        {
                          attrs: {
                            "html-type": "submit",
                            type: "primary",
                            loading: _vm.loading.find
                          }
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("lbl_find")) + " ")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("a-table", {
        attrs: {
          "data-source": _vm.dataSource,
          columns: _vm.columns,
          size: "small",
          pagination: {
            showTotal: function(total) {
              return _vm.$t("lbl_total_items", { total: total })
            },
            showSizeChanger: true,
            pageSizeOptions: _vm.PAGE_SIZE_OPTIONS,
            current: _vm.pagination.page,
            defaultPageSize: _vm.pagination.limit
          },
          loading: _vm.loading.find,
          scroll: { x: "calc(200px + 100%)", y: 900 }
        },
        on: { change: _vm.onChangeTable },
        scopedSlots: _vm._u([
          {
            key: "nullable",
            fn: function(text) {
              return _c("span", {}, [_vm._v(_vm._s(text || "-"))])
            }
          },
          {
            key: "currency",
            fn: function(text) {
              return _c("span", {}, [_vm._v(_vm._s(_vm._f("currency")(text)))])
            }
          },
          {
            key: "footer",
            fn: function() {
              return [
                _c(
                  "a-descriptions",
                  { attrs: { size: "small" } },
                  [
                    _c(
                      "a-descriptions-item",
                      { attrs: { label: _vm.$t("lbl_total_current") } },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm._f("currency")(_vm.dataReport.totalCurrent)
                            ) +
                            " "
                        )
                      ]
                    ),
                    _c(
                      "a-descriptions-item",
                      { attrs: { label: _vm.$t("lbl_1_to_30_days") } },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm._f("currency")(
                                _vm.dataReport.totalOutstanding1
                              )
                            ) +
                            " "
                        )
                      ]
                    ),
                    _c(
                      "a-descriptions-item",
                      { attrs: { label: _vm.$t("lbl_31_to_60_days") } },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm._f("currency")(
                                _vm.dataReport.totalOutstanding2
                              )
                            ) +
                            " "
                        )
                      ]
                    ),
                    _c(
                      "a-descriptions-item",
                      { attrs: { label: _vm.$t("lbl_61_to_90_days") } },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm._f("currency")(
                                _vm.dataReport.totalOutstanding3
                              )
                            ) +
                            " "
                        )
                      ]
                    ),
                    _c(
                      "a-descriptions-item",
                      { attrs: { label: _vm.$t("lbl_more_90_days") } },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm._f("currency")(
                                _vm.dataReport.totalOutstanding4
                              )
                            ) +
                            " "
                        )
                      ]
                    ),
                    _c(
                      "a-descriptions-item",
                      { attrs: { label: _vm.$t("lbl_grand_total") } },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm._f("currency")(_vm.dataReport.grandTotal)
                            ) +
                            " "
                        )
                      ]
                    )
                  ],
                  1
                )
              ]
            },
            proxy: true
          }
        ])
      }),
      _c(
        "div",
        { staticClass: "mt-2 text-right" },
        [
          _c(
            "a-button",
            {
              attrs: { type: "primary", loading: _vm.loading.download },
              on: { click: _vm.download }
            },
            [_vm._v(" " + _vm._s(_vm.$t("lbl_download")) + " ")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }